import React, { useState } from 'react';
import styles from './FaleConosco.module.css';
import { useTheme } from '../../ThemeContext';

function FaleConsoco() {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const { isDarkMode } = useTheme();

    const faqList = [
        { question: "Qual é o Horário de Atendimento?", answer: "Das 09:00 até 12:00 e 13:30 até 17:00. Caso você precise de um horário fora do horário comercial, entre em contato via whatsapp." },
        { question: "Como agendar consultas?", answer: "Clique na aba do menu superior 'Agenda sua Consulta', caso não consiga, entre em contato via whatsapp." },
        { question: "Qual a principal especialidade da advocacia?", answer: "Previdenciária. Atuamos há mais de 16 anos nesse ramo." },
        { question: "Os meus dados serão assegurados em sigilo?", answer: "Sim, todos os seus dados estarão de acordo com a Lei Geral de Proteção de Dados e com o código de ética da OAB" },

        // Adicione mais perguntas e respostas conforme necessário
    ];

    const handleToggleExpand = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    };

    const theme = isDarkMode ? styles.dark : styles.light;

    return (
        <div className={theme}>
            <div className={styles.container}>
                <h1>Abaixo Estão as Principais Dúvidas</h1>
                {faqList.map((faq, index) => (
                    <div key={index} className={styles.columnContainer}>
                        <div className={styles.question} onClick={() => handleToggleExpand(index)}>
                            <h3>{faq.question}</h3>
                            <button>{expandedIndex === index ? "-" : "+"}</button>
                        </div>
                        {expandedIndex === index && <p className={styles.answer}>{faq.answer}</p>}
                    </div>
                ))}


            </div>
        </div>
    );
}

export default FaleConsoco;
