import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';
import styles from './Artigos.module.css';
import { useTheme } from '../../ThemeContext';

function Artigos() {
    const { title } = useParams();
    const [article, setArticle] = useState(null);

    const { isDarkMode } = useTheme();
    const theme = isDarkMode ? styles.dark : styles.light;




    useEffect(() => {
        const fetchArticle = async () => {
            try {
                const response = await axios.get(`https://app.fuchshenrique.com.br/articles`);
                // Filtra o artigo pelo título da URL
                const filteredArticle = response.data.find((item) => item.title === title.replace(/_/g, ' '));
                setArticle(filteredArticle);
            } catch (error) {
                console.error('Erro ao buscar artigo:', error);
            }
        };
        fetchArticle();
    }, [title]);

    if (!article) {
        return <div>Carregando...</div>;
    }



    return (
        <div className={theme}>
            <div className={styles.background}>
                <div className={styles.container}>
                    <h1>{article.title}</h1>
                    <img src={article.image} alt={article.title} />

                    {article.texto_geral.split('\n').map((paragraph, index) => (
                        <div key={index} className={styles.paragraph}>
                            <p>{paragraph.trim()}</p>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
}

export default Artigos;
