import React from 'react'
import styles from './Local.module.css'
import { useTheme } from '../../ThemeContext'
import advocaciaSala1 from './RepositorioImagens/Advocacia Sala 1.jpeg'
import advocaciaSala2 from './RepositorioImagens/Advocacia Sala 2.jpeg'
import advocaciaSala3 from './RepositorioImagens/Advocacia Sala 3.jpeg'
import advocaciaSala4 from './RepositorioImagens/Advocacia Sala 4.jpeg'






function Local() {

    const { isDarkMode } = useTheme();
    const theme = isDarkMode ? styles.dark : styles.light;
    return (

        <div className={theme}>
            <div className={styles.container}>
                <div className={styles.containerEndereco}>
                    <h1>Localização</h1>
                    <iframe title="endereço" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7259.111705204905!2d-49.79043187864493!3d-26.090290558967776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dd93d9f0caa687%3A0x489c5479836af33!2sAv.%20Gen.%20Pl%C3%ADnio%20Tourinho%2C%202099%20-%20Bairro%20Bom%20Jesus%2C%20Rio%20Negro%20-%20PR%2C%2083880-000!5e0!3m2!1spt-BR!2sbr!4v1711286098819!5m2!1spt-BR!2sbr" width="600" height="450" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <div className={styles.containerImagem}>
                    <h1>Nosso Espaço</h1>
                    <div className={styles.containerListaImagens}>
                        <img src={advocaciaSala1} alt="Escritório" className={styles.imagens} loading="lazy" />
                        <img src={advocaciaSala2} alt="Advocacia" className={styles.imagens} loading="lazy" />
                        <img src={advocaciaSala3} alt="Fuchs" className={styles.imagens} loading="lazy" />
                        <img src={advocaciaSala4} alt="Henrique" className={styles.imagens} loading="lazy" />

                    </div>
                </div>

            </div>
        </div>

    )
}

export default Local
