import React, { useState, useEffect } from 'react';
import styles from './Blog.module.css';
import { useTheme } from '../../ThemeContext';
import { Link } from 'react-router-dom';

function Blog() {
    const { isDarkMode } = useTheme();
    const theme = isDarkMode ? styles.dark : styles.light;
    const [searchTerm, setSearchTerm] = useState('');
    const [articles, setArticles] = useState([]);
    const [searchedArticles, setSearchedArticles] = useState([]);
    const [error, setError] = useState('');

    const fetchArticles = async () => {
        try {
            const response = await fetch('https://app.fuchshenrique.com.br/articles', {
                mode: 'no-cors',
            });
            // Use 'response.ok' to handle HTTP errors
            if (response.ok) {
                const data = await response.json();
                setArticles(data);
                setError(''); // clear previous errors
            } else {
                setError('Failed to fetch articles from the server');
            }
        } catch (error) {
            console.error('Error fetching articles from the server: ' + error);
            setError('Error fetching articles from the server');
        }
    };

    const handleSearch = () => {
        if (!searchTerm.trim()) {
            setSearchedArticles([]);
            return;
        }

        const filteredArticles = articles.filter(article =>
            article.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchedArticles(filteredArticles);
    };

    useEffect(() => {
        fetchArticles();
    }, []);

    return (
        <div className={theme}>
            <div className={styles.container}>
                <header className={styles.header}>
                    <h1>Novidades</h1>
                    <p>Explore artigos sobre diversos temas relacionados ao direito.</p>
                    <div className={styles.searchBar}>
                        <input
                            type="text"
                            placeholder="Pesquisar por título..."
                            value={searchTerm}
                            onChange={(event) => setSearchTerm(event.target.value)}
                        />
                        <button onClick={handleSearch}>Pesquisar</button>
                    </div>
                </header>
                {error && <p className={styles.error}>{error}</p>}
                <ul className={styles.topicsList}>
                    {(searchTerm.trim() ? searchedArticles : articles).map(article => (
                        <Link to={`/blog/${article.title && article.title.replace(/\s+/g, '_')}`} key={article.id}>
                            <li>
                                <img src={article.image} alt={`Imagem de ${article.title}`} className={styles.imagem} />
                                <h2>{article.category}</h2>
                                <h1>{article.title}</h1>
                                <h3>{article.author}</h3>
                            </li>
                        </Link>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Blog;
