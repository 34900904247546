// Contatos.js

import React from 'react';
import styles from './ContatosTela.module.css';
import { useTheme } from '../../ThemeContext';
import { FaWhatsapp } from "react-icons/fa";
import { CgMail } from "react-icons/cg";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";





function ContatosTela() {
    const { isDarkMode } = useTheme();
    const theme = isDarkMode ? styles.dark : styles.light;

    return (

        <div className={theme}>
            <div className={styles.containerContato}>
                <h1>Links dos Contatos</h1>

                <div className={styles.contactItem}>


                    <a
                        href="https://wa.me/554784838521"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <p>Samuel Henrique: 47 98483-8521<FaWhatsapp className={styles.whatsappIcon} /></p>

                    </a>
                </div>
                <div className={styles.contactItem}>


                    <a
                        href="https://wa.me/554784308057"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <p>Jefferson Fuchs: 47 98430-8057<FaWhatsapp className={styles.whatsappIcon} /></p>

                    </a>
                </div>
                <div className={styles.contactItem}>

                    <a
                        href="mailto:fuchs.henrique@gmail.com"
                        target="_blank"
                        rel="noopener noreferrer"

                    >
                        <p>fuchs.henriqueadvogados@gmail.com<CgMail className={styles.gmailIcon} /></p>

                    </a>
                </div>


            </div>

            <div className={styles.containerExtra}>
                <h1>Informações Adicionais</h1>
                <p><FaRegClock className={styles.littleicons} />Horário de Atendimento: Segunda a Sexta, das 09:00 até 12:00 e das 13:30 até 17:00.</p>
                <p><MdOutlineLocationOn className={styles.littleicons} />Endereço: Av. Gen. Plínio Tourinho, 2099 - Bairro Bom Jesus, Rio Negro - PR, 83880-000.</p>
                <p><BsTelephone className={styles.littleicons} /> Telefone Fixo: (47) 3643-9801.</p>


            </div>



        </div>
    );
}

export default ContatosTela;
