import React, { useState, useEffect } from 'react';
import styles from './MensagemInicial.module.css';
import { useTheme } from '../../ThemeContext';
import Previdenciário from './RepositorioImagens/Previdenciário.png';
import Trabalhista from './RepositorioImagens/Trabalhista.png';
import Empresarial from './RepositorioImagens/Empresarial.png';
import Civel from './RepositorioImagens/Civel.png';
import Criminal from './RepositorioImagens/Criminal.png';
import Família from './RepositorioImagens/Família.png';
import Consumidor from './RepositorioImagens/Consumidor.png';
import Público from './RepositorioImagens/Público.png';
import Sucessão from './RepositorioImagens/Sucessão.png';
import Indenizatório from './RepositorioImagens/Indenizatório.png';
import { Link } from 'react-router-dom';


function MensagemInicial() {
    const { isDarkMode } = useTheme();
    const theme = isDarkMode ? styles.dark : styles.light;

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const images = [
        { src: Previdenciário, alt: "Advogado Previdenciário", caption: "Previdenciário" },
        { src: Trabalhista, alt: "Advogado Trabalhista", caption: "Trabalhista" },
        { src: Civel, alt: "Advogado Cível", caption: "Cível" },
        { src: Criminal, alt: "Advogado Criminalista", caption: "Penal ou Criminal" },
        { src: Família, alt: "Advogado Da Família", caption: "Familiar" },
        { src: Consumidor, alt: "Advogado Do Consumidor", caption: "Consumidor" },
        { src: Público, alt: "Advogado Do Direito Público", caption: "Público" },
        { src: Empresarial, alt: "Advogado Do Direito Privado", caption: "Privado" },
        { src: Sucessão, alt: "Advogado de Inventários e Sucessões", caption: "Inventário e Sucessões" },
        { src: Indenizatório, alt: "Advogado do Direito de Indenização", caption: "Indenizatório" }




    ];
    const [opacity, setOpacity] = useState(1); // Opacidade inicial
    const [nextImageOpacity, setNextImageOpacity] = useState(0); // Opacidade inicial da próxima imagem



    useEffect(() => {
        const interval = setInterval(() => {
            setNextImageOpacity(1); // Define a opacidade para 1 para iniciar a transição de entrada da próxima imagem
            setTimeout(() => {
                setOpacity(0); // Define a opacidade da imagem atual para 0 para iniciar a transição de saída
                setTimeout(() => {
                    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
                    setTimeout(() => {
                        setOpacity(1); // Define a opacidade de volta para 1 após a transição de saída
                        setNextImageOpacity(0); // Define a opacidade da próxima imagem de volta para 0 após a transição de entrada
                    }, 550); // Espera 500ms antes de definir a opacidade de volta para 1 e iniciar a transição de saída
                }, 550); // Espera 500ms antes de trocar a imagem principal e iniciar a transição de saída
            }, 0); // Espera 500ms antes de iniciar a transição de entrada da próxima imagem
        }, 3000); // Troca de imagem a cada 3 segundos

        return () => clearInterval(interval);
    }, [images.length]); // Adiciona images.length à lista de dependências




    return (
        <div className={theme}>
            <div className={styles.container}>


                <div className={styles.texto}>
                    <h1>Advogados Especialistas em Rio Negro e Mafra</h1>
                    <h2>Conheça Nossa Advocacia, Faça um Agendamento Conosco!</h2>
                    <Link to="/Cadastro"><button> Agendar Atendimento </button></Link>

                </div>

                <div className={styles.imagesContainer}>
                    <h1>Áreas de Atuação</h1>
                    {images.map((image, index) => (
                        <div key={index} className={`${styles.image} ${index === currentImageIndex ? styles.active : ''}`} style={{ opacity: index === currentImageIndex ? opacity : nextImageOpacity }}>
                            <img src={image.src} alt={image.alt} />
                            <p>{image.caption}</p>
                        </div>
                    ))}

                </div>

            </div>
        </div>
    );
}

export default MensagemInicial;
