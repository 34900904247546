import styles from './MenuBaixo.module.css';

import { useTheme } from '../../ThemeContext';


function MenuBaixo() {

    const { isDarkMode } = useTheme();
    const theme = isDarkMode ? styles.dark : styles.light;

    return (
        <div className={theme}>
            Desenvolvido por<a href='https://jeanhenrique.site/' target="_blank" rel="noopener noreferrer">Jean Henrique </a>e<a href='https://github.com/mayarall' target="_blank" rel="noopener noreferrer">Mayara Lovatto</a>
        </div >
    );
}

export default MenuBaixo