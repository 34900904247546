import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Cadastro.module.css';
import { useTheme } from '../../ThemeContext';
import axios from 'axios';

function Cadastro() {
    const navigate = useNavigate();

    // Alterado para refletir os nomes do banco de dados
    const [formData, setFormData] = useState({
        nomeCompletoAtendimento: '',  // Nome conforme no banco
        emailAtendimento: '',  // Nome conforme no banco
        telefoneAtendimento: '',  // Nome conforme no banco
        dataAgendamentoAtendimento: '',  // Nome conforme no banco
        horarioAtendimento: '',  // Nome conforme no banco
        idAdvocacia: 1,  // Incluído para associar o atendimento à advocacia
    });

    const [horariosDisponiveis, setHorariosDisponiveis] = useState([]);
    const [horariosAgendados, setHorariosAgendados] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [confirmation, setConfirmation] = useState(null);

    const { isDarkMode } = useTheme();
    const theme = isDarkMode ? styles.dark : styles.light;

    const today = new Date();
    const currentHour = today.getHours();
    const minimumDate = new Date(today);
    if (currentHour >= 9) {
        minimumDate.setDate(today.getDate() + 1);
    }
    const formattedMinimumDate = minimumDate.toISOString().split('T')[0];

    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 90);
    const formattedMaxDate = maxDate.toISOString().split('T')[0];

    const formatTimeBR = (timeStr) => {
        if (!timeStr) return '';
        const [hour, minute] = timeStr.split(':');
        return `${hour}:${minute}`;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const fetchData = useCallback(async (selectedDate) => {
        try {
            const dayOfWeek = new Date(selectedDate).getDay();
            if (dayOfWeek === 5 || dayOfWeek === 6) {
                setHorariosDisponiveis([]); // Bloquear agendamento no fim de semana
            } else {
                const response = await axios.get('https://desktop.fuchshenrique.com.br/horariosDisponiveis', {
                    params: { dataAgendamentoAtendimento: selectedDate },
                });

                const horariosComerciais = ["09:00", "09:45", "10:30", "11:15", "13:30", "14:15", "15:00", "15:45", "16:30"];

                const horariosAgendados = response.data; // Horários indisponíveis
                const horariosDisponiveisFiltrados = horariosComerciais.filter(horario => {
                    return !horariosAgendados.includes(horario); // Remove os horários já agendados
                });

                setHorariosDisponiveis(horariosDisponiveisFiltrados);
            }
        } catch (error) {
            console.error('Erro ao buscar horários disponíveis:', error);
        }
    }, []);

    useEffect(() => {
        if (formData.dataAgendamentoAtendimento) {
            fetchData(formData.dataAgendamentoAtendimento);
        }
    }, [formData.dataAgendamentoAtendimento, fetchData]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        for (const key in formData) {
            if (formData[key] === '') {
                return alert('Nenhum Campo Pode Estar Vazio!');
            }
        }

        if (formData.nomeCompletoAtendimento.length > 50) {
            return alert('O nome deve ter no máximo 50 caracteres!');
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.emailAtendimento)) {
            return alert('Por favor, insira um email válido!');
        }

        const telefoneRegex = /^[0-9]+$/;
        if (!telefoneRegex.test(formData.telefoneAtendimento)) {
            return alert('O telefone não pode conter letras!');
        }

        openConfirmation();
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const openConfirmation = () => {
        setConfirmation(true);
    };

    const closeConfirmation = () => {
        setConfirmation(false);
    };

    const submitForm = async (e) => {
        e.preventDefault();

        try {
            await axios.post('https://desktop.fuchshenrique.com.br/atendimentos', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            setFormData({
                nomeCompletoAtendimento: '',
                emailAtendimento: '',
                telefoneAtendimento: '',
                dataAgendamentoAtendimento: '',
                horarioAtendimento: '',
                idAdvocacia: 1,  // Incluído novamente para o próximo envio
            });

            alert('Atendimento cadastrado com sucesso!');
            navigate('/');
        } catch (error) {
            console.error('Erro ao enviar dados:', error);
            alert('Ocorreu um erro ao cadastrar o atendimento. Por favor, tente novamente.');
        }
    };

    return (
        <div className={theme}>
            <div className={styles.container}>
                <div className={styles.cadContainer}>
                    <h1>Agendamento</h1>
                    <form onSubmit={handleSubmit}>
                        <h2>Nome Completo:</h2>
                        <input
                            type="text"
                            id="nomeCompletoAtendimento"
                            name="nomeCompletoAtendimento"
                            value={formData.nomeCompletoAtendimento}
                            onChange={handleChange}
                            placeholder="Digite seu nome"
                            required
                        />

                        <h2 htmlFor="email">Email:</h2>
                        <input
                            type="email"
                            id="emailAtendimento"
                            name="emailAtendimento"
                            value={formData.emailAtendimento}
                            onChange={handleChange}
                            placeholder="Digite seu email"
                            required
                        />

                        <h2 htmlFor="telefone">Telefone:</h2>
                        <input
                            type="tel"
                            id="telefoneAtendimento"
                            name="telefoneAtendimento"
                            value={formData.telefoneAtendimento}
                            onChange={handleChange}
                            placeholder="Digite seu telefone"
                            required
                        />
                        <div className={styles.dataHorario}>
                            <div className={styles.data}>
                                <h2>Data:</h2>
                                <input
                                    type="date"
                                    id="dataAgendamentoAtendimento"
                                    name="dataAgendamentoAtendimento"
                                    value={formData.dataAgendamentoAtendimento}
                                    onChange={handleChange}
                                    min={formattedMinimumDate}
                                    max={formattedMaxDate}
                                    required
                                />

                            </div>

                            <div className={styles.horario}>
                                <h2>Horário:</h2>
                                <select
                                    id="horarioAtendimento"
                                    name="horarioAtendimento"
                                    value={formData.horarioAtendimento}
                                    onChange={handleChange}
                                    disabled={!formData.dataAgendamentoAtendimento}
                                    required
                                >
                                    <option value="" disabled>Selecione o Horário</option>
                                    {horariosDisponiveis.map(horarioAtendimento => (
                                        <option key={horarioAtendimento} value={horarioAtendimento}>{formatTimeBR(horarioAtendimento)}</option>
                                    ))}
                                </select>

                            </div>
                        </div>

                        <p>
                            Ao agendar, você declara que está de acordo com a{' '}
                            <a className={styles.botaoTermos} onClick={(e) => { e.preventDefault(); openModal(); }}>Política de Privacidade e os Termos de Uso.</a>
                        </p>
                        {showModal && (
                            <div className={styles.modal}>
                                <div className={styles.modalContent}>
                                    <button className={styles.close} onClick={closeModal}>&times;</button>
                                    <div className={styles.containerExtra}>
                                        <h2>Termos de Uso</h2>
                                        <p>
                                            Bem-vindo ao site da Fuchs & Henrique Advogados Associados.
                                        </p>
                                        <p>
                                            Ao acessar nosso site e utilizar nossos serviços, você concorda com estes termos de uso. Se você não concorda com algum destes termos, por favor, não continue usando nosso site.
                                        </p>
                                        {showModal && (
                                            <div className={styles.modal}>
                                                <div className={styles.modalContent}>
                                                    <button className={styles.close} onClick={closeModal}>&times;</button>
                                                    <div className={styles.containerExtra}>
                                                        <h2>Termos de Uso</h2>
                                                        <p>
                                                            Bem-vindo ao site da Fuchs & Henrique Advogados Associados.
                                                        </p>
                                                        <p>
                                                            Ao acessar nosso site e utilizar nossos serviços, você concorda com estes termos de uso. Se você não concorda com algum destes termos, por favor, não continue usando nosso site.
                                                        </p>
                                                        <p>
                                                            <strong>Informações Pessoais:</strong> Ao utilizar nosso site e fornecer informações pessoais como nome completo, número de telefone e email para agendar consultas, você concorda com nossa política de privacidade.
                                                        </p>
                                                        <p>
                                                            <strong>Uso Responsável:</strong> Você concorda em utilizar nosso site de forma responsável e de acordo com todas as leis e regulamentos aplicáveis.
                                                        </p>
                                                        <p>
                                                            <strong>Propriedade Intelectual:</strong> O conteúdo em nosso site, incluindo códigos de desenvolvimento, texto, logotipos, designs, é de propriedade exclusiva de Fuchs & Henrique Advogados Associados e dos desenvolvedores do site.
                                                        </p>
                                                        <p>
                                                            <strong>Links para Terceiros:</strong> Nosso site pode conter links para sites de terceiros. Não somos responsáveis pelo conteúdo ou práticas de privacidade desses sites.
                                                        </p>
                                                        <p>
                                                            <strong>Alterações nos Termos:</strong> Reservamo-nos o direito de fazer alterações nestes termos de uso a qualquer momento, sem aviso prévio. É sua responsabilidade revisar periodicamente estes termos para se manter informado sobre quaisquer mudanças.
                                                        </p>
                                                        <p>
                                                            <strong>Contato:</strong> Se você tiver alguma dúvida ou preocupação sobre estes termos de uso, entre em contato conosco.
                                                        </p>

                                                        <h2>Política de Privacidade</h2>
                                                        <p>
                                                            A sua privacidade é importante para nós. Esta política de privacidade descreve como coletamos, usamos e protegemos as informações pessoais que você fornece ao utilizar nosso site.
                                                        </p>
                                                        <p>
                                                            <strong>Informações Coletadas:</strong> Podemos coletar informações pessoais, como nome completo, número de telefone e email, quando você preenche um formulário de contato ou agendamento de consulta em nosso site.
                                                        </p>
                                                        <p>
                                                            <strong>Uso das Informações:</strong> Utilizamos as informações que coletamos para agendar consultas, discutir sobre os valores dos serviços prestados, responder às suas perguntas e fornecer informações adicionais sobre nossos serviços. Não compartilharemos suas informações pessoais com terceiros sem o seu consentimento, exceto quando necessário para fornecer os serviços solicitados por você.
                                                        </p>
                                                        <p>
                                                            <strong>Segurança:</strong> Implementamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, alteração, divulgação ou destruição.
                                                        </p>

                                                        <p>
                                                            <strong>Alterações na Política de Privacidade:</strong> Reservamo-nos o direito de fazer alterações nesta política de privacidade a qualquer momento, publicando uma versão atualizada em nosso site. Recomendamos que você revise esta política periodicamente para estar ciente de quaisquer alterações.
                                                        </p>
                                                        <p>
                                                            <strong>Exclusões:</strong> Se você quiser excluir as informações fornecidas neste agendamento, por favor, entre em contato conosco nas formas de contato fornecidas em nosso site.
                                                        </p>
                                                        <p>
                                                            <strong>Contato:</strong> Se você tiver alguma dúvida ou preocupação sobre nossa política de privacidade, entre em contato conosco.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        )}
                                    </div>
                                    <div className={styles.ImagemLado}>
                                        <h1>Local de Atendimento</h1>
                                        <iframe title="Local de Atendimento" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12014.059381015322!2d-49.7926945369323!3d-26.0897239349339!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dd93d9f0caa687%3A0x489c5479836af33!2sAv.%20Gen.%20Pl%C3%ADnio%20Tourinho%2C%202099%20-%20Bairro%20Bom%20Jesus%2C%20Rio%20Negro%20-%20PR%2C%2083880-000!5e0!3m2!1spt-BR!2sbr!4v1712237668734!5m2!1spt-BR!2sbr&midnight" width="500" height="400" allofullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                            </div>
                        )}

                        <button className={styles.botao} type="submit">Agendar</button>
                        {confirmation && (
                            <div className={styles.modalOverlay}>
                                <div className={styles.modalConfirmation}>
                                    <div className={styles.modalHeader}>
                                        <h2>Confirmação</h2>
                                        <button className={styles.modalClose} onClick={closeConfirmation}>X</button>
                                    </div>
                                    <div className={styles.modalBody}>
                                        <p>Tem certeza de que os dados inseridos estão corretos?</p>
                                    </div>
                                    <div className={styles.modalFooter}>
                                        <button onClick={closeConfirmation} className={styles.modalCancel}>Cancelar</button>
                                        <button type="submit" onClick={submitForm} className={styles.modalConfirm}>Confirmar</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
                <div className={styles.ImagemLado}>
                    <h1>Local de Atendimento</h1>
                    <iframe title="Local de Atendimento" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12014.059381015322!2d-49.7926945369323!3d-26.0897239349339!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dd93d9f0caa687%3A0x489c5479836af33!2sAv.%20Gen.%20Pl%C3%ADnio%20Tourinho%2C%202099%20-%20Bairro%20Bom%20Jesus%2C%20Rio%20Negro%20-%20PR%2C%2083880-000!5e0!3m2!1spt-BR!2sbr!4v1712237668734!5m2!1spt-BR!2sbr&midnight" width="500" height="400" allofullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    );
}

export default Cadastro;
