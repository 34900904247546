import React from 'react'
import styles from './Apresentacao.module.css'
import { FaWhatsapp } from "react-icons/fa";
import { useTheme } from '../../ThemeContext';


function Apresentacao() {

    const { isDarkMode } = useTheme();
    const theme = isDarkMode ? styles.dark : styles.light;

    const filteredTopics = [
        {

            name: 'Samuel Henrique',
            title: 'OAB/PR 59.087 | OAB/SC 43.674 - A',
            description: 'Advogado especializado em Direito Previdenciário, Civil e Criminal, com mais de 13 anos de experiência de atuação. Foca em atendimentos devidos para todos!',
            whatsapp: 'https://wa.me/554784838521'
        },
        {

            name: 'Jefferson Fuchs',
            title: 'OAB/PR 48.719 | OAB/SC 43.658 - A',
            description: 'Especialista em Direito Previdenciário, Civil e Trabalhista, com mais de 16 anos de experiência de atuação. Prioriza a melhor solução para cada cliente.',
            whatsapp: 'https://wa.me/554784308057'
        }
    ];

    return (
        <div className={theme}>
            <div className={styles.container}>

                <h1>Conheça Nossos Advogados</h1>

                <ul className={styles.topicsList}>
                    {filteredTopics.map(topic => (
                        <a key={topic.name} href={topic.whatsapp} target="_blank" rel="noopener noreferrer" className={styles.topicLink}>
                            <li>
                                <div className={styles.primeiraLinha}>
                                    <h1>{topic.name}</h1>
                                    <FaWhatsapp className={styles.whatsappIcon} />
                                </div>
                                <h2>{topic.title}</h2>
                                <h3>{topic.description}</h3>
                            </li>
                        </a>
                    ))}
                </ul>

            </div>
        </div>
    )
}

export default Apresentacao
