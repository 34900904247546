import React from 'react'
import styles from './Historia.module.css'

import { useTheme } from '../../ThemeContext'





function MensagemFinal() {

    const { isDarkMode } = useTheme();
    const theme = isDarkMode ? styles.dark : styles.light;
    return (

        <div className={theme}>
            <div className={styles.containerMensagem}>
                <h1>Nossa História</h1>
                <p>A advocacia começou com assistência judiciária, atendendo casos encaminhados pela OAB para pessoas
                    carentes. A advocacia foi fundada em outubro de 2008, com o nome de Jefferson Fuchs Advogado. No decorrer
                    do tempo a especialização foi sendo feita em direito previdenciário atendendo todas as áreas, com trabalho do advogado Fuchs
                    na justiça federal de Mafra-SC. Desde essa época já sonhava em trabalhar em direito previdenciário como forma
                    de contribuição que poderia trazer às pessoas.
                    Em 2015 houve a associação com o advogado Samuel Henrique, que possuía experiência com a área criminal e previdenciária. Assim, o escritório passou
                    a ser chamado como é hoje, Fuchs & Henrique Advogados Associados. Em conjunto, passaram a atender majoritariamente
                    casos previdenciários, sendo um escritório de referência na região.
                </p>




            </div>
        </div>

    )
}

export default MensagemFinal
