import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MenuCima from './componentes/MenuCima/MenuCima.jsx';
import MenuBaixo from './componentes/MenuBaixo/MenuBaixo.jsx';
import Cadastro from './componentes/AgendeSuaConsulta/Cadastro.jsx';
import Historia from './componentes/Página Inicial/Historia.jsx';
import FaleConosco from './componentes/Principais Dúvidas/FaleConosco.jsx';
import { ThemeProvider } from './ThemeContext.jsx';
import MensagemInicial from './componentes/Página Inicial/MensagemInicial.jsx';
import Apresentacao from './componentes/Página Inicial/Apresentacao.jsx';
import Contatos from './componentes/MenuBaixo/Contatos.jsx';
import Local from './componentes/Página Inicial/Local.jsx';
import ContatosTela from './componentes/Contatos/ContatosTela.jsx';
import NotFound from './componentes//NotFound/NotFound.jsx';
import Blog from './componentes/Blog/Blog.jsx';
import BlogAdm from './componentes/Blog/BlogAdm.jsx';
import Artigos from './componentes/Blog/Artigos.jsx';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {


  return (
    <ThemeProvider>
      <div>
        <Router>
          <ScrollToTop />
          <MenuCima />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route exact path="/" element={<><MensagemInicial /><Apresentacao /><Local /><Historia /><Contatos /><MenuBaixo /></>} />
            <Route path="/Duvidas" element={<><FaleConosco /><Contatos /><MenuBaixo /></>} />
            <Route path="/Cadastro" element={<><Cadastro /><Contatos /><MenuBaixo /></>} />
            <Route path="/Contatos" element={<ContatosTela />} />
            <Route path="/BlogAdm" element={<BlogAdm />} />
            <Route path="/Blog" element={<><Blog /><Contatos /><MenuBaixo /></>} />
            <Route path="/Blog/:title" element={<Artigos />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
