import React, { useState, useEffect } from 'react';
import styles from './BlogAdm.module.css';
import axios from 'axios'; // Use axios or fetch for making HTTP requests
import { Button, TextField, Paper, Typography } from '@mui/material';

function BlogAdm() {
    const [articles, setArticles] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editArticle, setEditArticle] = useState({});
    const [insertMode, setInsertMode] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const [image, setImage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [initialLoad, setInitialLoad] = useState(true);

    // Read username and password from environment variables
    const correctUsername = process.env.REACT_APP_USERNAME;
    const correctPassword = process.env.REACT_APP_PASSWORD;

    const handleLogin = () => {
        // Check credentials using environment variables
        if (username === correctUsername && password === correctPassword) {
            setLoggedIn(true);
            setErrorMessage('');
        } else {
            setErrorMessage('Login ou senha incorretos');
        }
    };

    useEffect(() => {
        if (loggedIn && initialLoad) {
            fetchArticles();
            setInitialLoad(false);
        }
    }, [loggedIn, initialLoad]);

    const fetchArticles = async () => {
        try {
            const response = await axios.get('https://app.fuchshenrique.com.br/articles');
            setArticles(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Erro ao buscar artigos do servidor:', error);
        }
    };

    const handleInsertClick = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('category', editArticle.category);
        formData.append('title', editArticle.title);
        formData.append('author', editArticle.author);
        formData.append('texto_geral', editArticle.texto_geral);
        formData.append('image', image);

        if (!editArticle.category || !editArticle.title || !editArticle.author || !editArticle.texto_geral || !image) {
            setErrorMessage('Por favor, preencha todos os campos');
            return;
        }

        try {
            const response = await axios.post('https://app.fuchshenrique.com.br/articles', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Article inserted successfully:', response.data);
            setInsertMode(false);
            setEditArticle({});
            setImage('');
            fetchArticles();
            setErrorMessage('');
        } catch (error) {
            console.error('Error inserting article:', error);
        }
    };

    const handleEditClick = async (article) => {
        try {
            setEditArticle({ ...article, imageUrl: `https://app.fuchshenrique.com.br/articles/${article.id}` });
            setEditMode(true);
            setShowButtons(false);
        } catch (error) {
            console.error('Erro ao buscar imagem do artigo:', error);
        }
    };

    const handleInputChange = (e, field) => {
        setEditArticle(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setImage(file);
            setEditArticle(prevState => ({
                ...prevState,
                image: file
            }));
        }
    };

    const handleSaveClick = () => {
        const { id, category, title, author, image, texto_geral } = editArticle;

        if (!category || !title || !author || !texto_geral) {
            setErrorMessage('Por favor, preencha todos os campos');
            return;
        }

        const formData = new FormData();
        formData.append('category', category);
        formData.append('title', title);
        formData.append('author', author);
        formData.append('texto_geral', texto_geral);
        if (image instanceof File) {
            formData.append('image', image);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const url = id ? `https://app.fuchshenrique.com.br/articles/${id}` : 'https://app.fuchshenrique.com.br/articles';

        axios[id ? 'put' : 'post'](url, formData, config)
            .then(response => {
                console.log(`Artigo ${id ? 'editado' : 'inserido'} com sucesso:`, response.data);
                setEditMode(false);
                setEditArticle({});
                setImage('');
                fetchArticles();
                setShowButtons(true);
            })
            .catch(error => {
                console.error(`Erro ao ${id ? 'editar' : 'inserir'} artigo:`, error);
            });
    };

    const handleCancelClick = () => {
        setEditMode(false);
        setEditArticle({});
        setShowButtons(true);
    };

    const handleDeleteClick = (id) => {
        axios.delete(`https://app.fuchshenrique.com.br/articles/${id}`)
            .then(response => {
                console.log('Artigo excluído com sucesso:', response.data);
                fetchArticles();
            })
            .catch(error => {
                console.error('Erro ao excluir artigo:', error);
            });
    };

    if (!loggedIn) {
        return (
            <Paper elevation={3} style={{ padding: '20px', maxWidth: '300px', margin: 'auto', marginTop: '50px' }}>
                <Typography variant="h5" gutterBottom>
                    Login
                </Typography>
                <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
                    Login
                </Button>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            </Paper>
        );
    }

    return (
        <div className={styles.container}>
            {insertMode || editMode ? null : <button onClick={() => setInsertMode(true)} className={styles.botaoInserir}>Inserir Novo Artigo</button>}
            {insertMode && (
                <div className={styles.insertform}>
                    <h1>Inserção de Artigos</h1>
                    {errorMessage && <div className={styles.error}>{errorMessage}</div>}
                    <form onSubmit={handleInsertClick} encType='multipart/form-data'>
                        <label htmlFor="category" className={styles.label}>Categoria</label>
                        <input id="category" type="text" placeholder="Insira a Categoria " onChange={(e) => handleInputChange(e, 'category')} className={styles.input} />

                        <label htmlFor="title" className={styles.label}>Título</label>
                        <input id="title" type="text" placeholder="Insira o Título" onChange={(e) => handleInputChange(e, 'title')} className={styles.input} />

                        <label htmlFor="author" className={styles.label}>Autor</label>
                        <input id="author" type="text" placeholder="Insira o Nome do Autor" onChange={(e) => handleInputChange(e, 'author')} className={styles.input} />

                        <label htmlFor="image" className={styles.label}>Imagem</label>
                        <input id="image" type="file" name="image" accept="image/*" onChange={handleImageChange} className={styles.input} />

                        <textarea
                            placeholder="Insira o corpo do artigo"
                            value={editArticle.texto_geral || ''}
                            onChange={(e) => handleInputChange(e, 'texto_geral')}
                            className={styles.input}
                        />

                        <div className={styles.botoes}>
                            <button onClick={handleInsertClick}>Inserir</button>
                            <button onClick={() => setInsertMode(false)} className={styles.botao2}>Cancelar</button>
                        </div>
                    </form>
                </div>
            )}

            {editMode ? (
                <div className={styles.insertform}>
                    <h1>Edição do Artigo</h1>
                    <label htmlFor="category" className={styles.label}>Categoria</label>
                    <input type="text" id="category" value={editArticle.category || ''} onChange={(e) => handleInputChange(e, 'category')} className={styles.input} />

                    <label htmlFor="title" className={styles.label}>Título</label>
                    <input type="text" id="title" value={editArticle.title || ''} onChange={(e) => handleInputChange(e, 'title')} className={styles.input} />

                    <label htmlFor="author" className={styles.label}>Autor</label>
                    <input type="text" id="author" value={editArticle.author || ''} onChange={(e) => handleInputChange(e, 'author')} className={styles.input} />

                    <label htmlFor="image" className={styles.label}>Imagem</label>
                    <input type="file" id="image" name="image" accept="image/*" onChange={handleImageChange} className={styles.input} />
                    {editArticle.image && typeof editArticle.image !== 'string' ? (
                        <img src={URL.createObjectURL(editArticle.image)} alt="Preview" className={styles.imagemPreview} />
                    ) : (
                        <img src={editArticle.image} alt="Preview" className={styles.imagemPreview} />
                    )}

                    <label htmlFor="texto_geral" className={styles.label}>Texto Geral</label>
                    <textarea id="texto_geral" value={editArticle.texto_geral || ''} onChange={(e) => handleInputChange(e, 'texto_geral')} className={styles.input} />

                    <div className={styles.botoes}>
                        <button onClick={handleSaveClick}>Salvar</button>
                        <button onClick={handleCancelClick}>Cancelar</button>
                    </div>
                </div>
            ) : (
                <div className={styles.gridContainer}>
                    {articles.map(article => (
                        <div className={styles.Lista} key={article.id}>
                            <img src={article.image} alt={article.title} className={styles.imagem} />
                            <h2>{article.category}</h2>
                            <h1>{article.title}</h1>
                            <h3>{article.author}</h3>
                            {showButtons && (
                                <div className={styles.botoes}>
                                    <button onClick={() => handleEditClick(article)}>Editar</button>
                                    <button onClick={() => handleDeleteClick(article.id)}>Excluir</button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default BlogAdm;
